import React from 'react';
import styled from '@emotion/styled';
import { CssEffectObj } from '../../global/sharedObjects';
import { Box } from '@mui/material';

/*---Queries---*/
import KundenDataQuery from '../../utility/data/kunden/kunden-query';

/*---Components---*/
import PageblockIconsFlex from '../pageBlockIconsFlex';

const KundenIMG = styled.img`
  width: 10em;
  height: auto;
  cursor: pointer;
  ${CssEffectObj.hoverSaturateFX}
`;

const CardKundenKunden = () => {
  const styles = {
    root: {
      paddingTop: '3em',
    },
    link: {
      padding: '2em 2em',
    },
  };
  const data = KundenDataQuery();
  const kunden = data.nodes[0].frontmatter.utilityKundenData;

  const items = (kunden: any) => {
    return kunden.map((item: any, index: number) => {
      return (
        <a
          style={styles.link}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <KundenIMG
            src={item.image ? item.image.publicURL : null}
            alt={item.alt}
          />
        </a>
      );
    });
  };

  return (
    <Box component="div" sx={styles.root}>
      <PageblockIconsFlex alignitems="center">
        {items(kunden)}
      </PageblockIconsFlex>
    </Box>
  );
};

/**
 * @description Returns existing customer logos.
 */

export default CardKundenKunden;
