import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '../../global';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';
import { HtmlContent } from '../../components/basic/html-content';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import CardKundenKunden from '../../components/Kunden_comps/cardKundenKunden';
import UserStories from '../../components/userStories';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const KundenPageTemplate = ({
  title,
  subtitle,
  image,
  kundenExperience,
  kundenUserStories,
}: {
  title: string;
  subtitle: string;
  image: any;
  kundenExperience: any;
  kundenUserStories: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: 'white',
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h1>{kundenExperience.headline}</h1>
      <p>{kundenExperience.content}</p>
      <hr />
      <p>Kunden Icons</p>
      <hr />
      <hr />
      {kundenUserStories.map((item: any, index: number) => {
        return (
          <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3>{item.headline}</h3>
                <p>{item.content}</p>
              </div>
              <div>
                <img
                  src={item.image}
                  alt={'image' + index}
                  width="300"
                  height="auto"
                />
              </div>
            </div>
            <hr />
          </>
        );
      })}

      <hr />
      <footer
        style={{
          backgroundColor: '#eeeeee',
        }}
      >
        Footer
      </footer>
    </section>
  );
};

KundenPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  kundenExperience: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  kundenUserStories: PropTypes.array,
};

const KundenPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />

      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
        bgImagePosition="left top"
      />
      <BlockStandard
        headline={frontmatter.kundenExperience.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.white}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        textAlignment={CvtBasicTheme.align.left as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.kundenExperience.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <CardKundenKunden />
      </BlockStandard>

      {UserStories(frontmatter.kundenUserStories, 8)}
    </Layout>
  );
};

export default KundenPage;

KundenPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const kundenPageQuery = graphql`
  query KundenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        kundenExperience {
          content
          headline
        }
        kundenUserStories {
          headline
          content
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
