import { useStaticQuery, graphql } from 'gatsby';

const KundenDataQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "Kunden-utility" } } }
        ) {
          nodes {
            frontmatter {
              utilityKundenData {
                alt
                image {
                  childImageSharp {
                    fluid(maxWidth: 526, quality: 92) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                  extension
                  publicURL
                }
                link
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark;
};

export default KundenDataQuery;
