import React from 'react';
import styled from '@emotion/styled';

function PageblockIconsFlex({
  alignitems,
  justifyContent,
  margintop,
  children,
}) {
  var alignItems = null;
  if (alignitems) {
    alignItems = 'align-items: ' + alignitems;
  } else {
    alignItems = 'align-items: flex-start';
  }

  var MarginTop = null;
  if (margintop) {
    MarginTop = 'margin-top: ' + margintop;
  } else {
    MarginTop = 'margin-top: 0%';
  }

  const IconsFlexBox = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${alignItems};
    ${MarginTop};
    justify-content: ${() =>
      justifyContent ? justifyContent : 'space-around'};
  `;

  return <IconsFlexBox>{children}</IconsFlexBox>;
}

export default PageblockIconsFlex;
